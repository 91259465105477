import { APP_INITIALIZER, ApplicationConfig, isDevMode, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { loggingInterceptor } from "./api/interceptors/logging.interceptor";
import { authInterceptor } from "./api/interceptors/auth.interceptor";
import { errorInterceptor } from "./api/interceptors/error.interceptor";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideToastr } from "ngx-toastr";
import { IMAGE_CONFIG, PRECONNECT_CHECK_BLOCKLIST } from "@angular/common";
import { environment } from "../environments/environment";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { FIREBASE_OPTIONS } from "@angular/fire/compat";
import { provideTransloco } from "@ngneat/transloco";

import { TranslationsService } from "./service/translations/translations.service";

export function loadLanguage(translationsService: TranslationsService) {
  return () => translationsService.load()
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withFetch(),
      withInterceptors([
        loggingInterceptor,
        authInterceptor,
        errorInterceptor
      ])),
    provideRouter(routes),
    provideAnimations(),
    provideToastr({
      toastClass: 'app-toast'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    {
      provide: IMAGE_CONFIG, useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      },
    },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    { provide: PRECONNECT_CHECK_BLOCKLIST, useValue: [environment.storeUrl, environment.storeOldUrl] },
    { provide: HAMMER_LOADER, useValue: () => import('@egjs/hammerjs') },
    { provide: LOCALE_ID, useValue: 'en-EN' },
    provideTransloco({
      config: {
        availableLangs: TranslationsService.availableLanguages,
        defaultLang: TranslationsService.defaultLang,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: loadLanguage,
      deps: [TranslationsService],
      multi: true,
    },
  ]
};
