import {RoutingParams, RoutingRegex} from "./routing-regex.helper";
import {UrlSegment} from "@angular/router";

export class HomePageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^home', segments: [0, 1], name: 'home', required: true },
    ];
    return HomePageRoutingRegex.regex(segments, params);
  }
}
export class ProfilePageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^profile', segments: [0, 1], name: 'profile', required: true },
    ];
    return ProfilePageRoutingRegex.regex(segments, params);
  }
}
export class CreatorPageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^creator', segments: [0, 1], name: 'creator', required: true },
    ];
    return CreatorPageRoutingRegex.regex(segments, params);
  }
}
export class AgencyOnboardingPageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^agency-onboarding', segments: [0, 1], name: 'agency-onboarding', required: true },
    ];
    return AgencyOnboardingPageRoutingRegex.regex(segments, params);
  }
}
export class TermsPageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^terms-of-service', segments: [0, 1], name: 'terms-of-service', required: true },
    ];
    return TermsPageRoutingRegex.regex(segments, params);
  }
}
export class PrivacyPageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^privacy-policy', segments: [0, 1], name: 'privacy-policy', required: true },
    ];
    return PrivacyPageRoutingRegex.regex(segments, params);
  }
}
export class CookiePageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^cookie', segments: [0, 1], name: 'cookie', required: true },
    ];
    return CookiePageRoutingRegex.regex(segments, params);
  }
}
export class SigninPageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^signin', segments: [0, 1], name: 'signin', required: true },
    ];
    return SigninPageRoutingRegex.regex(segments, params);
  }
}
export class SignupPageRoutingRegex extends RoutingRegex {
  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: '^signup', segments: [0, 1], name: 'signup', required: true },
    ];
    return SignupPageRoutingRegex.regex(segments, params);
  }
}
export class MainPageRoutingRegex extends RoutingRegex {
  private static emptyRegExp = '^$';

  static match(segments: UrlSegment[]): any {
    const params: RoutingParams[] = [
      { regexp: '^([a-z]{2})$', segments: [0], name: 'lang', required: false },
      { regexp: MainPageRoutingRegex.emptyRegExp, segments: [0, 1], name: 'request', required: false },
    ];
    return MainPageRoutingRegex.homePageRegex(segments, params);
  }

  private static homePageRegex(segments: UrlSegment[], params: any) {
    return RoutingRegex.regex(segments, params);
  }
}
