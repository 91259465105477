<ng-container *ngTemplateOutlet="notificationOutlet; context: {$implicit: notification}"></ng-container>
<ng-template #creators_profile_approved let-data>
  <app-n-profile-approved [data]="data" outlet="toast" (onRead)="action($event)"></app-n-profile-approved>
</ng-template>
<ng-template #creators_profile_rejected let-data>
  <app-n-profile-rejected [data]="data" outlet="toast" (onRead)="action($event)"></app-n-profile-rejected>
</ng-template>
<ng-template #creators_profile_expired>Profile Expired</ng-template>
<ng-template #new_subscriber let-data>
  <app-n-new-subscriber [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-subscriber>
</ng-template>
<ng-template #changed_subscription_from_free_to_paid let-data>
  <app-n-sub-change-to-payed [data]="data" outlet="toast" (onRead)="action($event)"></app-n-sub-change-to-payed>
</ng-template>
<ng-template #changed_subscription_from_paid_to_free let-data>
  <app-n-sub-change-to-free [data]="data" outlet="toast" (onRead)="action($event)"></app-n-sub-change-to-free>
</ng-template>
<ng-template #changed_subscription_from_paid_to_paid let-data>
  <app-n-sub-change [data]="data" outlet="toast" (onRead)="action($event)"></app-n-sub-change>
</ng-template>
<ng-template #donated let-data>
  <app-n-donated [data]="data" outlet="toast" (onRead)="action($event)"></app-n-donated>
</ng-template>
<ng-template #post_purchased let-data>
  <app-n-post-purchased [data]="data" outlet="toast" (onRead)="action($event)"></app-n-post-purchased>
</ng-template>
<ng-template #message_purchased let-data>
  <app-n-message-purchased [data]="data" outlet="toast" (onRead)="action($event)"></app-n-message-purchased>
</ng-template>
<ng-template #in_post_tag let-data>
  <app-n-in-post-tag [data]="data"></app-n-in-post-tag>
</ng-template>
<ng-template #in_post_comment_tag let-data>
  <app-n-in-post-comment [data]="data"></app-n-in-post-comment>
</ng-template>
<ng-template #new_post let-data>
  <app-n-new-post [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-post>
</ng-template>
<ng-template #new_post_comment let-data>
  <app-n-new-post-comment [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-post-comment>
</ng-template>
<ng-template #new_messages let-data>
  <app-n-new-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-message>
</ng-template>
<ng-template #new_multipart_message let-data>
  <app-n-new-multipart-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-multipart-message>
</ng-template>
<ng-template #new_text_message let-data>
  <app-n-new-text-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-text-message>
</ng-template>
<ng-template #new_photo_message let-data>
  <app-n-new-photo-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-photo-message>
</ng-template>
<ng-template #new_document_message let-data>
  <app-n-new-document-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-document-message>
</ng-template>
<ng-template #new_voice_message let-data>
  <app-n-new-voice-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-voice-message>
</ng-template>
<ng-template #new_audio_message let-data>
  <app-n-new-audio-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-audio-message>
</ng-template>
<ng-template #new_video_message let-data>
  <app-n-new-video-message [data]="data" outlet="toast" (onRead)="action($event)"></app-n-new-video-message>
</ng-template>
