import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StyleInjectorService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  injectStyle(styleContent: string) {
    if (isPlatformBrowser(this.platformId)) {
      const style = this.document.createElement('style');
      style.textContent = styleContent;

      const head = this.document.getElementsByTagName('head')[0];
      if (head) {
        head.appendChild(style);
      }
    }
  }
}
