<ng-container *ngTemplateOutlet="toast"></ng-container>
<ng-template #toast >
  <div class="notification toast">
    <app-profile-avatar [preview]="false" (click)="navigateToProfile()" size="notification" [url]="fromAvatar?.url"></app-profile-avatar>
    <div class="notification_body">
      <app-user-name (click)="navigateToProfile()" [displayName]="displayName" [deletedAt]="from?.deletedAt" [nickname]="this.from?.nickname" [kycVerified]="this.from?.isCreator && this.from?.kycVerified && !from?.deletedAt"></app-user-name>
      <div class="notification_body-text">
        <span *ngIf="data.message?.text">{{getMessageText(data.message.text)}}</span>
        <span *ngIf="!data.message?.text">{{translationsService.translate(translationsService.keys.NOTIFICATION_NOTIFICATION_SPAN_NEW_AUDIO_MESSAGE)}}</span>
      </div>
    </div>
    <app-icons name="close_1" class="notification_delete" width="40" height="40" fillColor="#DEDEEB" (click)="onRead.emit(data.id)"></app-icons>
  </div>
</ng-template>
