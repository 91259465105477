import {HttpInterceptorFn} from '@angular/common/http';
import {StorageService} from "../../service/storage.service";
import {inject} from "@angular/core";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(StorageService).getAuthToken();
  if (req.withCredentials) {
    const newReq = req.clone({
      withCredentials: false,
      headers: token ? req.headers.append('Authorization', `Bearer ${token}`) : req.headers,
    });
    return next(newReq);
  } else {
    return next(req);
  }
};
