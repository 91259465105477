import {Component, Inject, inject, OnInit} from '@angular/core';
import {Dialog, DIALOG_DATA, DialogModule} from "@angular/cdk/dialog";
import {MediaPreviewService} from "../../service/media-preview.service";
import {IHubFileResponse} from "desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface";
import {CommonModule} from "@angular/common";
import {TranslationsService} from "../../service/translations/translations.service";
import {EHubFileType} from "desiren-core-lib/lib/enums/hub/file-type.hub.enum";

export interface MediaPreviewDialogData {
  file?: IHubFileResponse;
  url?: string;
  autoplay?: boolean;
}

@Component({
  selector: 'app-media-preview-dialog',
  standalone: true,
  imports: [DialogModule],
  template: `<ng-container></ng-container>`,
  styleUrl: './media-preview-dialog.component.scss'
})
export class MediaPreviewDialogComponent implements OnInit {
  private service = inject(MediaPreviewService);
  private dialog = inject(Dialog);
  ngOnInit() {
    this.service.mediaPreview.subscribe(value => {
      if (value == null) {
        this.dialog.closeAll();
      } else {
        this.dialog.open(MediaPreviewDialog, {
          minWidth: '300px',
          data: {
            file: value,
            autoplay: true,
          }
        })
      }
    });
    this.service.mediaUrlPreview.subscribe(value => {
      if (value == null) {
        this.dialog.closeAll();
      } else {
        this.dialog.open(MediaPreviewDialog, {
          minWidth: '300px',
          data: {
            url: value,
          }
        })
      }
    });
  }
}
@Component({
  selector: 'media-preview-dialog',
  standalone: true,
  templateUrl: './media-preview-dialog.component.html',
  styleUrl: './media-preview-dialog.component.scss',
  imports: [
    CommonModule,
  ]
})
class MediaPreviewDialog {
  private service = inject(MediaPreviewService);
  public readonly translationsService: TranslationsService = inject(TranslationsService);
  file?: IHubFileResponse;
  imageUrl?: string;
  constructor(@Inject(DIALOG_DATA) public data: MediaPreviewDialogData) {
    console.log(data);
    this.file = data.file;
    this.imageUrl = data.url;
  }
  close() {
    this.service.closeFile();
  }

  get isVideoFile() {
    return this.file?.file.type == EHubFileType.VIDEO;
  }
}
