import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {StorageService} from "../service/storage.service";

export const signupGuard: CanActivateFn = (route, state) => {
  if (inject(StorageService).getAuthToken() != null) {
    inject(Router).navigate(['/']);
  }
  return inject(StorageService).getAuthToken() == null;
};
