<ng-container>
  <div class="cookie-dialog" [ngClass]="isOpened ? 'opened' : ''">
    <app-icons class="mobile-icon" name="cookie"></app-icons>
    <app-icons class="desktop-icon" name="cookie" width="80" height="80"></app-icons>
    <div class="cookie-dialog-text">
      {{translationsService.translate(translationsService.keys.COOKIE_DIALOG_TEXT_COOKIE_DIALOG)}} <a routerLink="/cookie">{{translationsService.translate(translationsService.keys.COOKIE_DIALOG_BUTTON_HERE)}}</a>.
    </div>
    <button class="btn btn-primary" (click)="acceptAndClose()">{{translationsService.translate(translationsService.keys.COOKIE_DIALOG_BUTTON_ACCEPT_ALL)}}</button>
  </div>
</ng-container>
