import {Routes} from '@angular/router';
import {signupGuard} from "./guards/signup.guard";
import {authGuard} from "./guards/auth.guard";
import {isCreatorGuard} from "./guards/is-creator.guard";
import {publicProfileGuard} from "./guards/public-profile.guard";
import {
  AgencyOnboardingPageRoutingRegex,
  CookiePageRoutingRegex,
  CreatorPageRoutingRegex,
  MainPageRoutingRegex,
  PrivacyPageRoutingRegex,
  ProfilePageRoutingRegex,
  SigninPageRoutingRegex,
  SignupPageRoutingRegex,
  TermsPageRoutingRegex
} from "./utils/routing.matcher";

export const routes: Routes = [
  {
    matcher: MainPageRoutingRegex.match,
    loadComponent: () => import('./components/main/main.component').then((m) => m.MainComponent),
    children: [
      {
        matcher: MainPageRoutingRegex.match,
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home-routing.module').then(mod => mod.HomeRoutingModule),
      },
      {
        path: 'desires',
        loadChildren: () => import('./modules/desires-home-feed/desires-home-feed.module').then(mod => mod.DesiresHomeFeedModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(mod => mod.AccountModule),
        canActivate: [authGuard],
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/messages/messages-routing.module').then(mod => mod.MessagesRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications-routing.module').then(mod => mod.NotificationsRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile-routing.module').then(mod => mod.MyProfileRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'statements',
        loadChildren: () => import('./modules/statements/statements.module').then(mod => mod.StatementsRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(mod => mod.StatisticsModule),
        canActivate: [authGuard],
      },
      {
        path: 'content',
        loadChildren: () => import('./modules/content/content-routing.module').then(mod => mod.ContentRoutingModule),
        canActivate: [authGuard, isCreatorGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings-routing.module').then(mod => mod.SettingsRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'queue',
        loadChildren: () => import('./modules/queue/queue-routing.module').then(mod => mod.QueueRoutingModule),
        canActivate: [authGuard],
      },
    ]
  },
  {
    path: 'unsubscribe',
    loadComponent: () => import('./components/unsubscribe/unsubscribe.component').then((m) => m.UnsubscribeComponent)
  },
  {
    matcher: SignupPageRoutingRegex.match,
    loadComponent: () => import('./components/signup/signup.component').then((m) => m.SignupComponent),
    canActivate: [signupGuard,],
  },
  {
    matcher: SigninPageRoutingRegex.match,
    loadComponent: () => import('./components/signup/signup.component').then((m) => m.SignupComponent),
    canActivate: [signupGuard,],
  },
  {
    matcher: ProfilePageRoutingRegex.match,
    loadComponent: () => import('./components/main/main.component').then((m) => m.MainComponent),
    loadChildren: () => import('./modules/profile/profile-routing.module').then(mod => mod.ProfileRoutingModule),
  },
  {
    matcher: AgencyOnboardingPageRoutingRegex.match,
    loadChildren: () => import('./modules/agency-onboarding/agency-onboarding.module').then(mod => mod.AgencyOnboardingRoutingModule),
    canActivate: [authGuard],
  },
  {
    matcher: CreatorPageRoutingRegex.match,
    loadComponent: () => import('./components/main/main.component').then((m) => m.MainComponent),
    loadChildren: () => import('./modules/public/public-routing.module').then(mod => mod.PublicRoutingModule),
    canActivate: [publicProfileGuard]
  },
  {
    matcher: TermsPageRoutingRegex.match,
    loadComponent: () => import('./components/static/terms-of-service/terms-of-service.component').then((m) => m.TermsOfServiceComponent),
  },
  {
    matcher: PrivacyPageRoutingRegex.match,
    loadComponent: () => import('./components/static/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
  },
  {
    matcher: CookiePageRoutingRegex.match,
    loadComponent: () => import('./components/static/cookies/cookies.component').then((m) => m.CookiesComponent),
  }
];
